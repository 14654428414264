<template>
    <div class="dyn-config-apartmentDetail">
        <app-header v-if="title" :title="title" :isShowBack="true"></app-header>
        <div class="apartment-form">
            <el-form ref="myForm" :model="myForm" :rules="rules">
                <div>
                    <el-form-item label-width="120px" label="房源类型" prop="type">
                        <el-select v-model="myForm.type" placeholder="请选择房源类型" @change="onChangeSelect(myForm.type)"
                            filterable disabled>
                            <el-option v-for="item in apartmentTypeList" :key="item.value" :label="item.label"
                                :value="item.value">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label-width="120px" label="学校名称" prop="apartmentCode">
                        <el-select v-model="myForm.apartmentCode" placeholder="请选择学校名称" :disabled="notEdit||partNotEdit">
                            <el-option v-for="item in schoolList" :key="item.value" :label="item.label"
                                :value="item.value">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label-width="120px" label="房源名称" v-if="config.nameShow" prop="name">
                        <el-input class="item_width" v-model.trim="myForm.name" :disabled="notEdit||partNotEdit"
                            placeholder="请输入房源名称">
                        </el-input>
                    </el-form-item>
                    <!-- <el-form-item v-if="myForm.type != 7" label-width="120px" :label="nameType" prop="name">
                        <el-input class="item_width" v-model.trim="myForm.name" :disabled="notEdit||partNotEdit"
                            :placeholder="nameTypePlaceholder">
                        </el-input>
                    </el-form-item> -->
                    <el-form-item v-if="config.provinceShow" label-width="120px" label="省份" prop="province">
                        <el-select v-model="myForm.province" filterable placeholder="请选择省份" :disabled="notEdit||partNotEdit"
                            @change="getCityList(myForm.province)">
                            <el-option v-for="it in provinceList" :key="it.value" :label="it.label" :value="it.label">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item v-if="config.cityShow" label-width="120px" label="城市" prop="city">
                        <el-select v-model="myForm.city" filterable placeholder="请选择城市" :disabled="notEdit||partNotEdit"
                            @change="getDistrictList(myForm.city)">
                            <el-option v-for="it in cityList" :key="it.value" :label="it.label" :value="it.label">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item v-if="config.districtShow" label-width="120px" label="县区" prop="district">
                        <el-select v-model="myForm.district" filterable placeholder="请选择县区" :disabled="notEdit||partNotEdit"
                            @change="getStreetList(myForm.district)">
                            <el-option v-for="it in districtList" :key="it.value" :label="it.label" :value="it.label">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item v-if="config.streetShow" label-width="120px" label="街道" prop="street">
                        <el-select v-model="myForm.street" filterable placeholder="请输入街道" :disabled="notEdit||partNotEdit">
                            <el-option v-for="it in streetList" :key="it.value" :label="it.label" :value="it.label">
                            </el-option>
                        </el-select>
                    </el-form-item>
                </div>
                <div>
                    <el-form-item v-if="config.buildingsShow" label-width="120px" label="楼栋数(栋)" prop="buildings">
                        <el-input-number type="number" v-model.number="myForm.buildings" :min="minVal"
                            :disabled="notEdit" clearable @change="numberChange(myForm.buildings)">
                        </el-input-number>
                    </el-form-item>
                </div>
                <div v-if="config.buildCodeShow" style="padding-bottom: 25px;">
                    <span v-for="(item,index) in buildingList" :key="item.buildCode">
                        <div style="display: inline-block;">
                            <div style="display: flex;">
                                <div
                                    style="color: #606266;font-size:14px;width:114px;line-height: 32px;padding-right:6px;text-align:right;">
                                    <span style="color: red;">*</span>楼栋名称</div>
                                <el-input style="display:inline-block;width:217px;" v-model.trim="item.buildName"
                                    minlength="1" maxlength="8" placeholder="请输入楼栋名称" :disabled="notEdit"
                                    @input="buidingNameChange(item,index)">
                                </el-input>
                            </div>
                            <span
                                style="color: #F56C6C;font-size:12px;padding-left:120px;">{{item.errFlag ? '请输入楼栋名称' : ' '}}</span>
                        </div>
                    </span>
                </div>
                <el-form-item v-if="config.regionManagerShow" label-width="120px" label="房源负责人" prop="regionManager">
                    <el-input class="item_width" v-model="myForm.regionManager" :disabled="notEdit"
                        placeholder="请输入房源负责人">
                    </el-input>
                </el-form-item>

                <!-- <el-form-item v-if="notEdit" label-width="120px" label="房东姓名" placeholder="请输入房东姓名">
                    <el-input class="item_width" v-model="myForm.landlordName" :disabled="notEdit">
                    </el-input>
                </el-form-item> -->
                <el-form-item v-if="config.managerPhoneNumShow" label-width="120px" label="负责人电话" prop="managerPhoneNum">
                    <el-input class="item_width" v-model="myForm.managerPhoneNum" :disabled="notEdit"
                        placeholder="请输入负责人电话">
                    </el-input>
                </el-form-item>
                <el-form-item v-if="config.carParkStatusShow" label-width="120px" label="房源停车场" prop="carParkStatus">
                    <el-select v-model="myForm.carParkStatus" placeholder="请选择房源停车场" :disabled="notEdit">
                        <el-option v-for="item in carParkStatusList" :key="item.value" :label="item.label"
                            :value="item.value">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item v-if="config.addressShow" label-width="120px" style="width:92%;" label="详细地址" prop="address">
                    <el-input id="place" maxlength="200" v-model="myForm.address" :disabled="notEdit||partNotEdit"
                        placeholder="请输入详细地址">
                    </el-input>
                </el-form-item>
                <!-- <div id="container"></div> -->

                <el-form-item v-if="config.labelsShow" label-width="120px" style="width:92%;" label="标签" prop="labels">
                    <el-checkbox-group v-model="myForm.labels" size="small" :disabled="notEdit">
                        <el-checkbox v-for="it in labelsList" :key="it.id" :label="it.id" border>{{it.name}}
                        </el-checkbox>
                    </el-checkbox-group>
                </el-form-item>
                <el-form-item v-if="config.pictureUrlListShow" label-width="120px" label="房源图片" style="width:100%;" prop="pictureUrlList">
                    <el-upload v-if="!notEdit" accept="image/*" list-type="picture-card" :action="picUrl"
                        :file-list="myForm.pictureUrlList" :limit="6" name="file"
                        :on-success="onSuccessByFormUploadImg()" :on-remove="onHandleRemove()"
                        :before-remove="onBeforeRemoveByUploadImg()" :on-preview="onPreviewByUploadImg"
                        :on-exceed="onExceed" :disabled="notEdit" :class="{'upload-disabled': notEdit}">
                        <i class="el-icon-plus"></i>
                    </el-upload>
                    <div class="img-list" v-if="notEdit">
                        <img v-for="it in myForm.pictureList" :key="it.src" :src="it.src" @click="showBig(it.src)" />
                    </div>
                </el-form-item>

                <!-- <el-form-item v-if="notEdit" label-width="120px" label="房源二维码">
                    <div class="img" v-if="notEdit">
                        <img :src="myForm.miniTwoCodeUrlStr" @click="showBig(myForm.miniTwoCodeUrlStr)" />
                    </div>
                </el-form-item> -->

                <el-form-item v-if="config.descriptionShow" label-width="120px" style="width:92%;" label="房源简介" prop="description">
                    <el-input maxlength="200" type="textarea" rows="6" show-word-limit placeholder="请输入房源简介"
                        v-model="myForm.description" :disabled="notEdit">
                    </el-input>
                </el-form-item>
                <div class="submitBox" v-show="!notEdit">
                    <el-button type="primary" @click="submit()">提交</el-button>
                </div>
            </el-form>
        </div>

        <el-dialog :visible.sync="dialogVisible" width="50%">
            <img width="100%" :src="dialogImageUrl" alt />
        </el-dialog>

    </div>
</template>
<script>
    import Vue from "vue";
    let validMobile = (rule, value, callback) => {
        if (value == "" || value == undefined) {
            callback();
        } else {
            let reg = /^1[3456789]\d{9}$/;
            if (!reg.test(value)) {
                callback(new Error("电话号码格式不对"));
            } else {
                callback();
            }
        }
    };
    let validBuildings = (rule, value, callback) => {
        if (value.toString() == "0") {
            callback(new Error("楼栋数在1~99之间"));
            return;
        }
        if (value == "" || value == undefined) {
            callback(new Error("请输入楼栋数"));
        } else {
            if (value < 1 || value > 99) {
                callback(new Error("楼栋数在1~99之间"));
            } else {
                callback();
            }
        }
    };

    let validBuildingBName = (rule, value, callback) => {
        let reg = /[^[\u4E00-\u9FA5]A-z0-9`~!@#$%^&*()_\-+=<>?:"{}|,.\/;'\\[\]·]/g;
        let reg1 = /^.{1,8}$/;
        if (reg.test(value)) {
            callback(new Error("只能包含大小写字母、数字、英文符号"));
        } else if (value && !reg1.test(value)) {
            callback('长度只能为1~8个字符');
        } else if (value == "" || value == undefined) {
            callback('请输入楼栋名称')
        } else {
            callback();
        }

    };
    let validName = (rule, value, callback) => {
        let reg = /^.{2,12}$/;
        if (!value) {
            callback(new Error("请输入名称"));
        } else if (!reg.test(value)) {
            callback(new Error("请输入2~12个字符"));
        } else {
            callback();
        }
    };
    export default {
        data() {
            let _this = this;
            return {
                //该2个字段只用于编辑场景
                cacheBuildings: 1,
                cacheBuildingsEditValue: 1,

                minVal: 1,
                maxVal: 99,
                oldBuildsVal: 1,
                dialogVisible: false,
                dialogImageUrl: false,
                map: '',
                notEdit: true,
                partNotEdit: false,
                title: '添加房源',
                nameType: '公寓名称',
                nameTypePlaceholder: '请输入公寓名称',
                picUrl: _this.UPLOAD_URL + "/user-service/weChat/uploadPic",
                myForm: {
                    type: '7',
                    apartmentCode: '',
                    name: '',
                    province: '',
                    city: '',
                    district: '',
                    street: '',
                    buildings: 1,
                    regionManager: '',
                    landlordName: '',
                    managerPhoneNum: '',
                    address: '',
                    carParkStatus: '0',
                    description: '',
                    miniTwoCodeUrlStr: '',
                    labels: [],
                    pictureUrlList: [],
                    pictureList: [],
                    // addressCoordinates: {
                    //     lng: '',
                    //     lat: '',
                    // },
                },
                buildingList: [{
                    buildName: '1栋',
                    buildCode: 1,
                    errFlag: false
                }],
                schoolList: [],
                provinceList: [],
                cityList: [],
                districtList: [],
                streetList: [],
                apartmentTypeList: [{
                        value: "1",
                        label: "公寓"
                    },
                    {
                        value: "2",
                        label: "小区"
                    },
                    {
                        value: "3",
                        label: "酒店"
                    },
                    {
                        value: "4",
                        label: "民宿"
                    },
                    // {
                    //   value: "5",
                    //   label: "别墅"
                    // },
                    {
                        value: "6",
                        label: "工业园"
                    },
                    {
                        value: "7",
                        label: "学校"
                    }
                ],
                carParkStatusList: [{
                        label: "无",
                        value: "0"
                    },
                    {
                        label: "有",
                        value: "1"
                    }
                ],
                labelsList: [],
                selsectMacList: [],
                rules: {
                    type: [{
                        required: true,
                        message: "请选择房源类型",
                        trigger: ['change']
                    }],
                    name: [{
                        required: true,
                        // message: "长度在 2 到 12 个字符",
                        trigger: ['change'],
                        validator: validName
                    }],
                    apartmentCode: [{
                        required: true,
                        message: "请选择学校名称",
                        trigger: ['change']
                    }],
                    province: [{
                        required: true,
                        message: "请选择省份",
                        trigger: ['change']
                    }],
                    city: [{
                        required: true,
                        message: "请选择城市",
                        trigger: ['change']
                    }],
                    district: [{
                        required: true,
                        message: "请选择区县",
                        trigger: ['change']
                    }],
                    street: [{
                        required: false,
                        message: "请选择街道",
                        trigger: ['change']
                    }],
                    buildings: [{
                        required: true,
                        // message: "请输入楼栋数",
                        trigger: ['change'],
                        validator: validBuildings,
                    }],
                    regionManager: [{
                        required: false,
                        message: "请输入房源负责人",
                        trigger: ['change']
                    }],
                    managerPhoneNum: [{
                        required: false,
                        trigger: ['change'],
                        validator: validMobile
                    }],
                    carParkStatus: [{
                        required: false,
                        message: "请选择停车情况",
                        trigger: ['change']
                    }],
                    buildName: [{
                        required: true,
                        message: "请输入楼栋名称",
                        trigger: ['change'],

                    }],
                    address: [{
                        required: true,
                        message: "请输入详细地址",
                        trigger: ['change']
                    }],
                    labels: [{
                        required: true,
                        message: "请选择标签",
                        trigger: ['change']
                    }],
                    pictureUrlList: [{
                        required: true,
                        message: "请上传房源图片",
                        trigger: ['change']
                    }],
                    description: [{
                        required: false,
                        message: "请输入房源简介",
                        trigger: ['change']
                    }],
                },
                config: {
                    nameShow: true,
                    provinceShow: true,
                    cityShow: true,
                    districtShow: true,
                    streetShow: true,
                    buildingsShow: true,
                    buildCodeShow: true,
                    regionManagerShow: false,
                    managerPhoneNumShow: false,
                    carParkStatusShow: false,
                    addressShow: true,
                    labelsShow: false,
                    pictureUrlListShow: false,
                    descriptionShow: false,

                    // apartmentCodeRequired: true,
                    // provinceRequired: true,
                    // cityRequired: true,
                    // districtRequired: false,
                    // streetRequired: false,
                    // buildingsRequired: true,
                    // buildNameRequired: true,
                    // regionManagerRequired: false,
                    // managerPhoneNumRequired: false,
                    // carParkStatusRequired: false,
                    // addressRequired: false,
                    // labelsRequired: false,
                    // pictureUrlListRequired: false,
                    // descriptionRequired: false,
                },

            };
        },
        activated() {
            console.log("apartmentDetail-detail created!!");
            if (this.$route.params.type == "show") {
                this.notEdit = true;
            } else {
                this.notEdit = false;
            }
            this.partNotEdit = false;
            this.$nextTick(() => {
                if (this.$refs["myForm"]) {
                    this.$refs["myForm"].resetFields();
                }
            })
            this.getProvince();
            this.getApartmentList2();
            
            // 学校类型的房源
            if(this.$route.query.type == 7) {
                let houeConfig = []
                this.getHouseConfig().then(res => {
                    houeConfig = res.houseConfig
                    console.log('houeConfig:',houeConfig)
                    houeConfig.forEach(item => {
                        if(item.property == "house_name") {
                            this.config.nameShow = item.display
                            this.rules.name[0].required = item.fill
                        }
                        if(item.property == "province") {
                            this.config.provinceShow = item.display
                            this.rules.province[0].required = item.fill
                        }
                        if(item.property == "city") {
                            this.config.cityShow = item.display
                            this.rules.city[0].required = item.fill
                        }
                        if(item.property == "district") {
                            this.config.districtShow = item.display
                            this.rules.district[0].required = item.fill
                        }
                        if(item.property == "street") {
                            this.config.streetShow = item.display
                            this.rules.street[0].required = item.fill
                        }
                        if(item.property == "building_num") {
                            this.config.buildingsShow = item.display
                            this.rules.buildings[0].required = item.fill
                        }
                        if(item.property == "building_name") {
                            this.config.buildNameShow = item.display
                            this.rules.buildName[0].required = item.fill
                        }
                        if(item.property == "responsible_name") {
                            this.config.regionManagerShow = item.display
                            this.rules.regionManager[0].required = item.fill
                        }
                        if(item.property == "responsible_mobile") {
                            this.config.managerPhoneNumShow = item.display
                            this.rules.managerPhoneNum[0].required = item.fill
                        }
                        if(item.property == "house_parking") {
                            this.config.carParkStatusShow = item.display
                            this.rules.carParkStatus[0].required = item.fill
                        }
                        if(item.property == "detail_address") {
                            this.config.addressShow = item.display
                            this.rules.address[0].required = item.fill
                        }
                        if(item.property == "tags") {
                            this.config.labelsShow = item.display
                            this.rules.labels[0].required = item.fill
                        }
                        if(item.property == "house_pic") {
                            this.config.pictureUrlListShow = item.display
                            this.rules.pictureUrlList[0].required = item.fill
                        }
                        if(item.property == "house_desc") {
                            this.config.descriptionShow = item.display
                            this.rules.description[0].required = item.fill
                        }
                    })

                });
                // // 现在是根据原型来配置的，后续需要根据接口返回的实际情况来配置
                // this.rules.apartmentCode[0].required = this.config.apartmentCodeRequired;
                // this.rules.province[0].required = this.config.provinceRequired;
                // this.rules.city[0].required = this.config.cityRequired;
                // this.rules.district[0].required = this.config.districtRequired;

                // this.rules.street[0].required = this.config.streetRequired;
                // this.rules.buildings[0].required = this.config.buildingsRequired;
                // this.rules.buildName[0].required = this.config.buildNameRequired;
                // this.rules.regionManager[0].required = this.config.regionManagerRequired;
                // this.rules.managerPhoneNum[0].required = this.config.managerPhoneNumRequired;
                // this.rules.carParkStatus[0].required = this.config.carParkStatusRequired;
                // this.rules.address[0].required = this.config.addressRequired;
                // this.rules.labels[0].required = this.config.labelsRequired;
                // this.rules.pictureUrlList[0].required = this.config.pictureUrlListRequired;
                // this.rules.description[0].required = this.config.descriptionRequired;
            
            }else {
                this.getApartmentList();
            }
            console.log('====================:', this.notEdit)
            this.title = ["添加房源", "编辑房源", "房源详情"][{
                add: 0,
                edit: 1,
                show: 2
            } [this.$route.params.type]];
            this.myForm = {
                type: '7',
                apartmentCode: '',
                name: '',
                province: '',
                city: '',
                district: '',
                street: '',
                buildings: 1,
                regionManager: '',
                landlordName: '',
                managerPhoneNum: '',
                address: '',
                carParkStatus: '0',
                description: '',
                labels: [],
                pictureUrlList: [],
                pictureList: [],
                miniTwoCodeUrlStr: '',
                // addressCoordinates: {
                //     lng: '',
                //     lat: '',
                // },
            };
            this.buildingList = [{
                buildName: '1栋',
                buildCode: 1,
                errFlag: false
            }];
            this.nameType = '公寓名称';
            this.nameTypePlaceholder = '请输入公寓名称';
            if (this.$route.params.type == 'add') {
                this.oldBuildsVal = 1;
            }
            
            if (this.$route.params.type == "edit" || this.$route.params.type == "show") {
                this.getDetail();
            }
            // this.initMap();

        },
        methods: {
            submit() {
                console.log('buildingList:', this.buildingList)
                let cancelSelsectMacList = [];
                let newSelsectMacList = [];
                // 检验楼栋名称是否填写 如未填写不可提交
                let errFlag = false;
                this.buildingList.find(item => {
                    return errFlag = item.errFlag;
                })
                if (errFlag) {
                    return
                }

                this.$refs["myForm"].validate((valid) => {
                    if (!valid) {
                        return;
                    } else {
                        let dto = {
                            landlordId: this.cache.getLS("userInfo")["userId"], //获取全局房东id
                            type: this.myForm.type,
                            name: this.myForm.name,
                            province: this.myForm.province,
                            city: this.myForm.city,
                            district: this.myForm.district,
                            street: this.myForm.street,
                            buildings: this.myForm.buildings.toString(),
                            regionManager: this.myForm.regionManager,
                            landlordName: this.myForm.landlordName,
                            managerPhoneNum: this.myForm.managerPhoneNum,
                            address: this.myForm.address,
                            carParkStatus: this.myForm.carParkStatus,
                            strdescriptioneet: this.myForm.description,
                            labels: this.myForm.labels.join(","),
                            buildingList: this.buildingList,
                            // latitude: this.myForm.addressCoordinates.lat,
                            // longitude: this.myForm.addressCoordinates.lng,

                        }
                        if (this.myForm.apartmentCode) {
                            dto.apartmentCode = this.myForm.apartmentCode;
                        }

                        // 创建图片提交字段
                        dto.pictureList = [];
                        this.myForm.pictureUrlList.forEach((data, index) => {
                            dto.pictureList.push(data.url);
                        });

                        let url = "landlord-service/apartment/createApartment";
                        let message = "添加房源成功";
                        if (this.$route.params.type == 'edit') {
                            url = "landlord-service/apartment/updateApartment";
                            dto.id = this.$route.params.id;
                            message = "编辑房源成功";
                        }
                        this.post(url, dto, {
                            isUseResponse: true,
                        }).then(res => {
                            this.$message({
                                showClose: true,
                                message: message,
                                type: "success"
                            });
                            this.$back();
                        })
                    }
                })

            },
            getDetail() {
                let dto1 = {
                    id: this.$route.params.id
                };
                let hostname = Vue.config.BASE_URL;
                for (let i = 0; i < Vue.config.urlOpt.length; i++) {
                    if (hostname == Vue.config.urlOpt[i][0]) {
                        hostname = Vue.config.urlOpt[i][1];
                        break;
                    }
                }
                let dto2 = {
                    text: `${hostname}/wechatsweepcode/wave?id=${this.$route.params.id}&type=1`
                };
                this.buildingList = [];

                Promise.all([
                    this.post("/landlord-service/apartment/queryApartment", dto1),
                    this.post("/tenant-service/utils/generateQrCode", dto2)
                ]).then(vals => {
                    let data = vals[0];
                    this.getCityList(data.province);
                    this.getDistrictList(data.city);
                    this.getStreetList(data.district);
                    this.myForm.labels = data.labels.split(",");
                    this.myForm.pictureUrlList = [];
                    this.myForm.pictureList = [];
                    data.pictureList.forEach((item, index) => {
                        this.myForm.pictureList.push({
                            src: item
                        });
                        this.myForm.pictureUrlList.push({
                            name: `${index}.jpg`,
                            url: item
                        });
                    });
                    this.myForm.carParkStatus = data.carParkStatus ? data.carParkStatus.toString() : "0";
                    //    二维码
                    this.myForm.miniTwoCodeUrlStr = "data:image/png;base64," + vals[1].result;

                    // this.myForm.addressCoordinates.lat = data.latitude;
                    // this.myForm.addressCoordinates.lng = data.longitude;
                    this.myForm.type = data.type.toString();
                    this.myForm.apartmentCode = data.apartmentCode;
                    this.myForm.name = data.name;
                    this.myForm.province = data.province;
                    this.myForm.city = data.city;
                    this.myForm.district = data.district;
                    this.myForm.street = data.street;
                    this.myForm.buildings = data.buildings;
                    this.cacheBuildings = data.buildings;
                    this.cacheBuildingsEditValue = data.buildings;
                    this.myForm.regionManager = data.regionManager;
                    this.myForm.landlordName = data.landlordName;
                    this.myForm.managerPhoneNum = data.managerPhoneNum;
                    this.myForm.address = data.address;
                    this.myForm.description = data.description;
                    if(data.canEdit == 0 && this.$route.params.type == 'edit') {
                        this.partNotEdit = true;
                    }else {
                        this.partNotEdit = false;
                    }

                    // let center = new qq.maps.LatLng(data.latitude, data.longitude);
                    // this.map = new qq.maps.Map(document.getElementById("container"), {
                    //     center: center,
                    //     zoom: 18
                    // });
                    // this.setMarker(center);
                    // //编辑时initMap中的监听地图点击事件不生效  于是这里重新监听一遍
                    // this.addListener();

                    this.oldBuildsVal = Number(data.buildings);
                    if (data.buildingList && data.buildingList.length >= 1) {
                        data.buildingList.forEach((item, index) => {
                            this.buildingList.push({
                                buildCode: item.buildCode,
                                buildName: item.buildName
                            })
                        })
                        // 当所在楼栋无房间时 接口不会返回楼栋名称数据 需要进行数据初始化(兼容旧数据 进行处理)
                        if (data.buildings > data.buildingList.length) {
                            for (let i = data.buildingList.length + 1; i <= data.buildings; i++) {
                                this.buildingList.splice(i, 0, {
                                    buildCode: i,
                                    buildName: i + '栋'
                                })
                            }
                        }
                    } else {
                        // 当所在楼栋无房间时 接口不会返回楼栋名称数据 需要进行数据初始化(兼容旧数据 进行处理)
                        for (let i = 1; i <= data.buildings; i++) {
                            this.buildingList.push({
                                buildCode: i,
                                buildName: i + '栋'
                            })
                        }
                    }
                })
            },
            // initMap() {
            //     let _this = this;
            //     if (this.$route.params.type == 'add') {
            //         _this.map = new qq.maps.Map(document.getElementById("container"), {
            //             center: new qq.maps.LatLng(39.916527, 116.397128),
            //             zoom: 18
            //         });
            //     }
            //     //实例化自动完成
            //     var ap = new qq.maps.place.Autocomplete(document.getElementById('place'));
            //     console.log('iiiiiiiiiiiiiiiiiiiiii')
            //     var keyword = "";
            //     var selectIndex = "";
            //     //添加监听事件
            //     qq.maps.event.addListener(ap, "confirm", function (res) {
            //         keyword = res.value;
            //         selectIndex = res.index;
            //         searchService.search(keyword);
            //     });
            //     //调用Poi检索类。用于进行本地检索、周边检索等服务。
            //     var searchService = new qq.maps.SearchService({
            //         complete: function (results) {
            //             if (results.type === "CITY_LIST") {
            //                 searchService.setLocation(results.detail.cities[0].cityName);
            //                 searchService.search(keyword);
            //                 return;
            //             }
            //             var pois = results.detail.pois;
            //             var latlngBounds = new qq.maps.LatLngBounds();
            //             for (var i = 0, l = pois.length; i < l; i++) {
            //                 if (l >= selectIndex) {
            //                     if (selectIndex == i) {
            //                         _this.myForm.address = keyword;
            //                         // 返回的pois数据条目大于等于选择的下标时
            //                         var poi = pois[i];
            //                         _this.myForm.addressCoordinates = poi.latLng;
            //                         latlngBounds.extend(poi.latLng);
            //                         _this.setMarker(poi.latLng);
            //                         break
            //                     }
            //                 } else if (keyword == pois[i].name) {
            //                     _this.myForm.address = keyword;
            //                     // 当返回的pois数据条目小于选择的下标时
            //                     var poi = pois[i];
            //                     console.log('poi.latLng:', poi.latLng)
            //                     _this.myForm.addressCoordinates = poi.latLng;
            //                     latlngBounds.extend(poi.latLng);
            //                     _this.setMarker(poi.latLng);
            //                     break
            //                 }
            //             }
            //             _this.map.fitBounds(latlngBounds);
            //         }
            //     });
            //     _this.addListener();
            // },
            // addListener() {
            //     let _this = this;
            //     // 监听点击事件
            //     qq.maps.event.addListener(_this.map, "click", function (event) {
            //         console.log('-----:event', event)
            //         let latitude = event.latLng.getLat();
            //         let longitude = event.latLng.getLng();
            //         let center = new qq.maps.LatLng(latitude, longitude);
            //         _this.setMarker(center);
            //         let geocoder = new qq.maps.Geocoder({
            //             complete: function (result) {
            //                 _this.myForm.address = result.detail.address; //腾讯地图选择
            //                 _this.myForm.addressCoordinates = result.detail.location; //选择的位置坐标
            //             }
            //         });
            //         let coord = new qq.maps.LatLng(latitude, longitude);
            //         geocoder.getAddress(coord);
            //     });
            // },
            // setMarker(center) {
            //     let _this = this;
            //     let _marker = _this.cache.getR("appFormMarker");
            //     if (_marker) {
            //         // 清除标记
            //         _marker.setMap(null);
            //     }
            //     let marker = new qq.maps.Marker({
            //         position: center,
            //         map: _this.map
            //     });
            //     _this.cache.setR("appFormMarker", marker);
            // },
            //获取学校列表
            getApartmentList2() {
                var dto = {
                    pageNumber: "1",
                    pageSize: "99999",
                    landlordId: this.cache.getLS("userInfo")["roleType"] == "1" ?
                        null : this.cache.getLS("userInfo")["userId"]
                };
                this.post("/landlord-service/school/info", {}).then(
                    res => {
                        let returnData = {};
                        res.forEach(data => {
                            returnData[data.id] = data.name;
                        });
                        res = JSON.parse(JSON.stringify(res).replace(/name/g, "label"));
                        res = JSON.parse(JSON.stringify(res).replace(/code/g, "value"));
                        this.schoolList = res;
                    }
                );
            },
            //获取小区标签基础数据
            getApartmentList() {
                this.post("landlord-service/base/getLabelAll", {}).then(res => {
                    res = JSON.parse(JSON.stringify(res).replace(/labelId/g, "id"));
                    res.forEach(data => {
                        data.id = data.id.toString();
                    });
                    this.labelsList = res;
                });
            },
            getProvince() {
                this.post("/landlord-service/base/getProvinces").then(res => {
                    res = JSON.parse(JSON.stringify(res).replace(/name/g, "label"));
                    res = JSON.parse(JSON.stringify(res).replace(/id/g, "value"));
                    this.provinceList = res;
                });
            },
            // 获取城市列表
            getCityList(name) {
                this.post("/landlord-service/base/getCitys?name=" + name).then(res => {
                    res = JSON.parse(JSON.stringify(res).replace(/name/g, "label"));
                    res = JSON.parse(JSON.stringify(res).replace(/id/g, "value"));
                    this.cityList = res;
                });
                this.myForm.city = '';
                this.myForm.district = '';
                this.myForm.street = '';
            },
            //获取区县
            getDistrictList(name) {
                this.post("/landlord-service/base/getAreas?name=" + name).then(res => {
                    res = JSON.parse(JSON.stringify(res).replace(/name/g, "label"));
                    res = JSON.parse(JSON.stringify(res).replace(/id/g, "value"));
                    this.districtList = res;
                });
                this.myForm.district = '';
                this.myForm.street = '';
            },
            //获取街道
            getStreetList(name) {
                this.post("/landlord-service/base/getStreet?name=" + name).then(res => {
                    res = JSON.parse(JSON.stringify(res).replace(/name/g, "label"));
                    res = JSON.parse(JSON.stringify(res).replace(/id/g, "value"));
                    this.streetList = res;
                });
                this.myForm.street = '';
            },
            numberChange(value) {
                if (this.$route.params.type == 'edit') {
                    if (value < this.cacheBuildings) {
                        this.myForm.buildings = `${this.cacheBuildings}`
                        if (this.cacheBuildingsEditValue > this.cacheBuildings) {
                            value = this.cacheBuildings
                        } else {
                            return
                        }
                    }
                    this.cacheBuildingsEditValue = value
                }
                if (value > 99) {
                    return
                }
                if (this.oldBuildsVal < 1) {
                    this.buildingList = [];
                    for (let i = 1; i <= value; i++) {
                        this.buildingList.push({
                            buildName: i + '栋',
                            buildCode: i,
                        })
                    }
                } else if (this.oldBuildsVal < value) { //往后拼接
                    for (let i = parseInt(this.oldBuildsVal); i < value; i++) {
                        this.buildingList.splice(i + 1, 0, {
                            buildName: i + 1 + '栋',
                            buildCode: i + 1,
                        })
                    }
                } else if (this.oldBuildsVal > value) {
                    this.buildingList = this.buildingList.slice(0, value)
                }
                this.oldBuildsVal = value;
            },
            buidingNameChange(item, index) {
                let reg = /[^[\u4E00-\u9FA5]A-z0-9`~!@#$%^&*()_\-+=<>?:"{}|,.\/;'\\[\]·]/g;
                let reg1 = /^.{1,8}$/;
                this.buildingList.forEach((it, idx) => {
                    if (index == idx) {
                        if (!item.buildName) {
                            it.errFlag = true;
                        } else {
                            it.errFlag = false;
                        }
                    }
                })
                this.$forceUpdate();
            },
            //配合图片上传确认item
            onPreviewByUploadImg(file) {
                this.dialogImageUrl = file.url;
                this.dialogVisible = true;
            },
            //表单类型图片上传回调
            onSuccessByFormUploadImg() {
                return (response, file, fileList) => {
                    this.myForm.pictureUrlList.push({
                        url: response.data.picPath
                    });
                }
            },
            // 图片删除
            onHandleRemove() {
                return (file, fileList) => {
                    this.myForm.pictureUrlList = fileList;
                };
            },
            //文件上传数量超过限制
            onExceed() {
                this.$message({
                    message: "文件上传数量超过限制，请删除后重新上传",
                    type: "warning"
                });
            },
            //图片上传的图片删除
            onBeforeRemoveByUploadImg() {
                return (file, fileList) => {
                    return new Promise((resolve, reject) => {
                        let _this = this;
                        let is = true;
                        if (is) {
                            this.$confirm("确认删除此图片吗？", "提示", {
                                    confirmButtonText: "确定",
                                    cancelButtonText: "取消",
                                    type: "warning"
                                })
                                .then(() => {
                                    console.log('fileList:', fileList)
                                    resolve();

                                    return true
                                })
                                .catch(function () {
                                    reject();
                                });
                        } else {
                            resolve();
                        }
                    })
                }
            },
            //放大图片
            showBig(val) {
                console.log('val:', val)
                this.dialogImageUrl = val;
                this.dialogVisible = true;
            },
            onChangeSelect(value) {
                this.nameType = ["", "公寓", "小区", "酒店", "民宿", "别墅", "房源", "房源"][Number(value)] + '名称';
                this.nameTypePlaceholder = '请输入' + ["", "公寓", "小区", "酒店", "民宿", "别墅", "房源", "房源"][Number(value)] + '名称'
            }

        }
    };
</script>
<style lang="scss" scoped>
    .dyn-config-apartmentDetail {
        min-width: 1250px;
    }

    .apartment-form {
        padding: 0px 90px;
    }

    .apartment-form-groupCode {
        font-size: 14px;
        color: #999;
        padding-left: 56px;
        padding-bottom: 20px;
    }

    .submitBox {
        text-align: center;
        padding-top: 20px;
        padding-bottom: 20px;
    }

    .el-switch {
        line-height: 17px;
    }

    // #container {
    //     width: 92%;
    //     height: 400px;
    //     margin-left: 121px;
    // }

    /deep/.el-form-item {
        display: inline-block;
        // width: 50%;
        // margin-left: 0 !important;
    }

    /deep/.el-input {
        // width: 100%;
    }

    /deep/.el-input-number {
        width: 217px;
    }

    .item_width {
        width: 217px;
    }

    .img-list {
        white-space: nowrap;
        overflow-x: auto;

        img {
            margin-right: 10px;
            height: 160px;
            cursor: pointer;
        }
    }

    .img {
        img {
            max-width: 500px;
            cursor: pointer;
        }
    }
</style>